import { Box, Heading, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { ArrowUpIcon, InfoIcon } from "@chakra-ui/icons";
import { get, isEmpty } from "lodash";

function getDirection(angle) {
  var directions = [
    "North",
    "North-East",
    "East",
    "South-East",
    "South",
    "South-West",
    "West",
    "North-West",
  ];
  var index = Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45) % 8;
  return directions[index];
}
const Shitter = ({ shitter, index }) => {
  return (
    <VStack border="1px" w="100%" h="100%" p={8} spacing={4}>
      {!isEmpty(shitter) ? (
        <>
          <Heading textAlign="center">{shitter.name}</Heading>
          <Heading fontSize="lg" textAlign="center" minH="100px">
            {index === 0 ? (
              <Text>
                The {shitter.name} has the highest probability of shitting on
                you today with a {(get(shitter, "number", 0) * 100).toFixed(0)}%
                chance of dropping a deuce right on your head{" "}
              </Text>
            ) : (
              <Text>
                The {shitter.name} has a{" "}
                {(get(shitter, "number", 0) * 100).toFixed(0)}% chance of
                dropping a deuce right on your head today{" "}
              </Text>
            )}
          </Heading>
          <Image src={shitter.image} h="80" alt="The shitter" />

          <VStack rounded="xl" bg="#EEE" p={4}>
            <Heading fontSize="md">
              The bird may approach from the{" "}
              {getDirection(get(shitter, "bearing", 0))}
            </Heading>
            <Box
              transform={`rotate(${get(shitter, "bearing", 0).toFixed(2)}deg)`}
            >
              <Icon as={ArrowUpIcon} h={24} w={24} />
            </Box>
            <Text fontSize="lg">{get(shitter, "bearing", 0).toFixed(0)}°</Text>
          </VStack>
        </>
      ) : (
        <VStack>
          <Heading>No avian offenders found</Heading>
        </VStack>
      )}
    </VStack>
  );
};

export default Shitter;
