import { extendTheme } from "@chakra-ui/react";

const config = {
  components: {
    Tooltip: {
      baseStyle: {
        textAlign: "center",
      },
    },
    Th: {
      baseStyle: {
        borderLeft: "1px solid black",
        textAlign: "center",
      },
    },
  },
};

export default extendTheme(config);
