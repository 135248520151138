import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  ChakraProvider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Stack,
  Text,
  Tooltip,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { ArrowUpIcon, InfoIcon } from "@chakra-ui/icons";
import { addDays, format } from "date-fns";
import { get, inRange, isEmpty, map, times, toPlainObject } from "lodash";
import { useEffect, useState } from "react";

import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Shitter from "./components/Shitter";
import confused from "./assets/confused_shit.svg";
import deuces from "./assets/deuces.svg";
import icon from "./assets/icon.png";
import ky from "ky";
import locationIcon from "./assets/location.svg";
import shitstorm from "./assets/hail_storm.svg";
import sunny from "./assets/sunny.svg";
import theme from "./common/theme";
import twnLogo from "./assets/twn.png";

const AutoComplete = chakra(GooglePlacesAutocomplete, {
  shouldForwardProp: (prop) => ["selectProps", "apiKey"].includes(prop),
});

function radians(n) {
  return n * (Math.PI / 180);
}
function degrees(n) {
  return n * (180 / Math.PI);
}

function getBearing(startLat, startLong, endLat, endLong) {
  startLat = radians(startLat);
  startLong = radians(startLong);
  endLat = radians(endLat);
  endLong = radians(endLong);

  var dLong = endLong - startLong;

  var dPhi = Math.log(
    Math.tan(endLat / 2.0 + Math.PI / 4.0) /
      Math.tan(startLat / 2.0 + Math.PI / 4.0)
  );
  if (Math.abs(dLong) > Math.PI) {
    if (dLong > 0.0) dLong = -(2.0 * Math.PI - dLong);
    else dLong = 2.0 * Math.PI + dLong;
  }

  return (degrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;
}

const App = () => {
  const [locationFieldValue, setLocationFieldValue] = useState(null);
  const [forecast, setForecast] = useState([]);
  const [latLng, setLatLng] = useState({});
  const [shitter, setShitter] = useState({});

  useEffect(() => {
    const placeId = get(locationFieldValue, "value.place_id");
    if (!placeId) return;
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    placesService.getDetails({ placeId, fields: ["geometry"] }, (res) => {
      const [lat, lng] = [
        res.geometry.location.lat(),
        res.geometry.location.lng(),
      ];
      setLatLng({ lat, lng });
    });
  }, [locationFieldValue]);

  useEffect(() => {
    if (isEmpty(latLng)) return;
    ky.get(
      new URL(
        "api/get-bird",
        process.env.NODE_ENV === "production"
          ? window.location.origin
          : "http://localhost:8788"
      ).href,
      {
        searchParams: {
          lat: latLng.lat,
          lng: latLng.lng,
        },
      }
    )
      .json()
      .then((response) => {
        setShitter(
          response.list
            .map((item) => ({
              ...item,
              bearing: getBearing(latLng.lat, latLng.lng, item.lat, item.lng),
            }))
            .filter((_, i) => i < 6)
        );
        setForecast(
          map(
            times(6, (index) => {
              let daysFromNow = index;
              const dayOfWeek = addDays(new Date(), daysFromNow).getDay();
              if (dayOfWeek === 4) {
                // Lol we don't do thursdays
                daysFromNow++;
              }

              const percentage =
                get(response, `temperature[${daysFromNow}]`, 0) * 100;

              const finalDate = addDays(new Date(), daysFromNow);
              let iconSrc = sunny;
              let forecastDescription = "Sunny with a chance of feces";
              switch (true) {
                case inRange(percentage, 50, 75):
                  forecastDescription = "Light Droppings";
                  iconSrc = confused;
                  break;
                case inRange(percentage, 75, 90):
                  forecastDescription = "Shitstorm";
                  iconSrc = shitstorm;
                  break;
                case inRange(percentage, 90, 100):
                  forecastDescription = "Deadly Deuces";
                  iconSrc = deuces;
                  break;
                default:
                  break;
              }
              return {
                dayname: format(finalDate, "EEEE"),
                date: format(finalDate, "d LLL"),
                pos: percentage.toFixed(0),
                iconSrc,
                forecast: forecastDescription,
              };
            })
          )
        );
      });
  }, [latLng]);

  let alert = {
    status: "info",
    title: "No active alerts",
    description: "Stay vigilant, random attacks can occur at any time",
  };

  const todaysRisk = get(forecast, "[0].pos", 0);

  switch (true) {
    case isEmpty(todaysRisk):
      break;
    case inRange(todaysRisk, 80, 90):
      alert = {
        status: "warning",
        title: "Elevated risk of fecal bombardment",
        description:
          "Stay indoors and ensure proper protection measures are in place when venturing outside is a necessity.",
      };
      break;
    case inRange(todaysRisk, 90, 100):
      alert = {
        status: "error",
        title: "Shitnado inbound",
        description:
          "Shitnados cause extreme damage to property and human health. Tune to FM 69.69 and stay indoors at all times until this emergency situation has been resolved. If you or a loved one has been struck by the shitnado, immediately induce vomiting and dial S-H-I-T.",
      };
      break;
    default:
      break;
  }

  return (
    <ChakraProvider theme={theme}>
      <VStack p={{ base: 4, xl: 8 }} minH="100vh" justify="space-between">
        <VStack w="100%" h="100%" spacing={8}>
          <Stack
            w={{ base: "90%", xl: "6xl" }}
            justify={{ base: "center", sm: "start" }}
            align={{ base: "center", sm: "center" }}
            pb={4}
            mb={2}
            borderBottom="1px solid black"
            direction={{ base: "column", sm: "row" }}
          >
            <Image src={icon} alt="bsf icon" height="100px" />
            <Heading>Bird Shit Forecast</Heading>
          </Stack>
          <Stack
            w={{ base: "90%", xl: "6xl" }}
            justify={{ base: "center", sm: "start" }}
            align={{ base: "center", sm: "center" }}
            direction={{ base: "column", sm: "row" }}
          >
            <Text w="48">Enter Your location</Text>
            <HStack w={{ base: "90%", xl: "6xl" }}>
              <Box w="100%">
                <AutoComplete
                  apiKey="AIzaSyDck8U0sz0z28_q821RQE2iXH8viyed99M"
                  selectProps={{
                    onChange: setLocationFieldValue,
                    value: locationFieldValue,
                  }}
                />
              </Box>
              <IconButton
                icon={<Image src={locationIcon} h={12} />}
                onClick={() => {
                  navigator.geolocation.getCurrentPosition((loc) => {
                    if (isEmpty(toPlainObject(loc))) return;
                    setLatLng({
                      lat: get(loc, "coords.latitude", 0),
                      lng: get(loc, "coords.longitude", 0),
                    });
                  });
                }}
              />
            </HStack>
          </Stack>
          <Alert
            w={{ base: "90%", xl: "6xl" }}
            status={alert.status}
            flexDirection={{ base: "column", md: "row" }}
            textAlign={{ base: "center", md: "left" }}
            mt={0}
          >
            <AlertIcon />
            <AlertTitle>{alert.title}</AlertTitle>
            <AlertDescription>{alert.description}</AlertDescription>
          </Alert>
          <Stack
            border="1px solid black"
            w={{ base: "90%", xl: "6xl" }}
            direction={{ base: "column", md: "row" }}
            spacing={0}
          >
            {forecast.length ? (
              forecast.map((day, index) => (
                <VStack border="1px" w="100%" py={2} key={`day-${index}`}>
                  <VStack w="100%" borderBottom="1px" pb={2}>
                    <Heading fontSize="xl">{day.dayname}</Heading>
                    <Heading fontSize="lg">{day.date}</Heading>
                  </VStack>
                  <Text textAlign="center">{day.forecast}</Text>
                  <Image src={day.iconSrc} alt="Confused shit" height="150px" />
                  <HStack justify="center">
                    <Tooltip label="Probability Of Shit - Indicates the likelihood that you'll be shat on">
                      <InfoIcon />
                    </Tooltip>
                    <Text>POS {day.pos}%</Text>
                  </HStack>
                </VStack>
              ))
            ) : (
              <HStack justify="center" w="100%" p={16}>
                <Heading>No shit to display</Heading>
              </HStack>
            )}
          </Stack>

          <Grid
            w={{ base: "90%", xl: "6xl" }}
            templateColumns={{
              base: "repeat(1, 1fr)",
              lg: "repeat(2, 1fr)",
            }}
            gap={6}
          >
            {shitter.length
              ? shitter.map((shit, index) => (
                  <GridItem w="100%">
                    <Shitter shitter={shit} index={index} />
                  </GridItem>
                ))
              : null}
          </Grid>
        </VStack>
        <Stack
          borderTop="1px"
          w={{ base: "90%", xl: "6xl" }}
          justify="space-between"
          direction={{ base: "column", md: "row" }}
        >
          <Text>
            BIRD SHIT FORECAST (BSF) is not responsible for any financial gains
            and/or losses resulting from comments made on this world wide
            website.
          </Text>
          <Image src={twnLogo} h={24} w={48} />
        </Stack>
      </VStack>
    </ChakraProvider>
  );
};

export default App;
